h1 {
    position: absolute;
    margin-top: 30vh;
    width: 100%;
    text-align: center;
    font-size: 333%;
    font-family: sans-serif;
    color: grey;
    opacity: 0.5;
  }
  
  canvas {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
  }
  