// 
// _demos.scss
// 

// Lightbox 

.mfp-popup-form {
  max-width: 1140px;
}
.mfp-close{
color: var(--#{$prefix}body-color) !important;
}

// Modals

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

[dir="rtl"]{
.modal-open{
  padding-left: 0px !important;
}
}

// Icon demo ( Demo only )
.icon-demo-content {
  text-align: center;
  color: $gray-500;

  i{
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
    color: var(--#{prefix}secondary-color);
    transition: all 0.4s;
  }

  .col-lg-4 {
    margin-top: 24px;

    &:hover {
      i {
        color: $primary;
        transform: scale(1.5);
      }
    }
  }
}


// Grid

.grid-structure {
  .grid-container {
      background-color: var(--#{$prefix}gray-100);
      margin-top: 10px;
      font-size: .8rem;
      font-weight: $font-weight-medium;
      padding: 10px 20px;
  }
}


// card radio

.card-radio{
background-color: var(--#{$prefix}secondary-bg);
border: 2px solid var(--#{$prefix}border-color);
border-radius: var(--#{$prefix}border-radius);
padding: 1rem;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

&:hover{
  cursor: pointer;
}
}

.card-radio-label{
display: block;
}


.card-radio-input{
display: none;
&:checked + .card-radio {
  border-color: $primary !important;
}
}

.navs-carousel{
.owl-nav{
    margin-top: 16px;
    button{
        width: 30px;
        height: 30px;
        line-height: 28px !important;
        font-size: 20px !important;
        border-radius: 50% !important;
        background-color: rgba($primary, 0.25) !important;
        color: $primary !important;
        margin: 4px 8px !important;
    }
}
}


